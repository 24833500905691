import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { DataGridPro, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
import { Paper, ToggleButton, ToggleButtonGroup, Box, Typography, IconButton, Stack, Tooltip, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { userObject } from '../UserContext';

const COUNTY_CODES = {
//   'A': 'Albany',
//   'B': 'Broome',
  'C': 'Columbia',
  'D': 'Dutchess',
//   'E': 'Erie',
//   'F': 'Franklin',
  'G': 'Greene',
//   'H': 'Hamilton',
//   'K': 'Kings',
  'L': 'Delaware',
//   'M': 'Monroe',
  'N': 'Nassau',
  'O': 'Orange',
  'P': 'Putnam',
//   'Q': 'Queens',
  'R': 'Rockland',
  'S': 'Suffolk',
//   'T': 'Tioga',
  'U': 'Ulster',
  'V': 'Sullivan',
  'W': 'Westchester',
//   'X': 'Essex',
//   'Y': 'Yates',
};

const MUNICIPAL_CODES = {
  'C': {  // Columbia
    'C06': 'Hudson',
    'C20': 'Ancram',
    'C22': 'Austerlitz',
    'C24': 'Canaan',
    'C26': 'Chatham',
    'C28': 'Claverack',
    'C30': 'Clermont',
    'C32': 'Copake',
    'C34': 'Gallatin',
    'C36': 'Germantown',
    'C38': 'Ghent',
    'C40': 'Greenport',
    'C42': 'Hillsdale',
    'C44': 'Kinderhook',
    'C46': 'Livingston',
    'C48': 'New Lebanon',
    'C50': 'Stockport',
    'C52': 'Stuyvesant',
    'C54': 'Taghkanic'
  },
  'D': {  // Dutchess
    'D01': 'Amenia',
    'D02': 'Beacon',
    'D13': 'Poughkeepsie City',
    'D20': 'Amenia',
    'D22': 'Beekman',
    'D24': 'Clinton',
    'D26': 'Dover',
    'D28': 'East Fishkill',
    'D30': 'Fishkill',
    'D32': 'Hyde Park',
    'D34': 'LaGrange',
    'D36': 'Milan',
    'D38': 'North East',
    'D40': 'Pawling',
    'D42': 'Pine Plains',
    'D44': 'Pleasant Valley',
    'D46': 'Poughkeepsie',
    'D48': 'Red Hook',
    'D50': 'Rhinebeck',
    'D52': 'Stanford',
    'D54': 'Union Vale',
    'D54': 'Wappinger',
    'D56': 'Washington'
  },
  'G': {  // Greene
    'G20': 'Ashland',
    'G22': 'Athens',
    'G24': 'Cairo',
    'G26': 'Catskill',
    'G28': 'Coxsackie',
    'G30': 'Durham',
    'G32': 'Greenville',
    'G34': 'Halcott',
    'G36': 'Hunter',
    'G38': 'Jewett',
    'G40': 'Lexington',
    'G42': 'New Baltimore',
    'G44': 'Prattsville',
    'G46': 'Windham'
  },
  'L': {  // Delaware
    'L20': 'Andes',
    'L22': 'Bovina',
    'L24': 'Colchester',
    'L26': 'Davenport',
    'L28': 'Delhi',
    'L30': 'Deposit',
    'L32': 'Franklin',
    'L34': 'Hamden',
    'L36': 'Hancock',
    'L38': 'Harpersfield',
    'L40': 'Kortright',
    'L42': 'Masonville',
    'L44': 'Meredith',
    'L46': 'Middletown',
    'L48': 'Roxbury',
    'L50': 'Sidney',
    'L52': 'Stamford',
    'L54': 'Tompkins',
    'L56': 'Walton'
  },
  'N': {  // Nassau
    'N05': 'Glen Cove City',
    'N06': 'Glen Cove',
    'N09': 'Long Beach City',
    'N10': 'Long Beach',
    'N20': 'Hempstead',
    'N22': 'North Hempstead',
    'N24': 'Oyster Bay'
  },
  'O': {  // Orange
    'O09': 'Middletown',
    'O11': 'Newburgh (City)',
    'O13': 'Port Jervis',
    'O20': 'Blooming Grove',
    'O22': 'Chester',
    'O24': 'Cornwall',
    'O26': 'Crawford',
    'O28': 'Deerpark',
    'O30': 'Goshen',
    'O32': 'Greenville',
    'O34': 'Hamptonburgh',
    'O36': 'Highlands',
    'O38': 'Minisink',
    'O40': 'Monroe',
    'O42': 'Montgomery',
    'O44': 'Mount Hope',
    'O46': 'Newburgh',
    'O48': 'New Windsor',
    'O50': 'Tuxedo',
    'O52': 'Wallkill',
    'O54': 'Warwick',
    'O56': 'Wawayanda',
    'O58': 'Woodbury'
  },
  'S': {  // Suffolk
    'S01': 'Babylon',
    'S02': 'Brookhaven',
    'S03': 'East Hampton',
    'S04': 'Huntington',
    'S05': 'Islip',
    'S06': 'Riverhead',
    'S07': 'Shelter Island',
    'S08': 'Smithtown',
    'S09': 'Southampton',
    'S10': 'Southold'
  },
  'U': {  // Ulster
    "U08": "Kingston City",
    'U20': 'Denning',
    'U22': 'Esopus',
    'U24': 'Gardiner',
    'U26': 'Hardenburgh',
    'U28': 'Hurley',
    'U30': 'Kingston',
    'U32': 'Lloyd',
    'U34': 'Marbletown',
    'U36': 'Marlborough',
    'U38': 'New Paltz',
    'U40': 'Olive',
    'U42': 'Plattekill',
    'U44': 'Rochester',
    'U46': 'Rosendale',
    'U48': 'Saugerties',
    'U50': 'Shandaken',
    'U52': 'Shawangunk',
    'U54': 'Ulster',
    'U56': 'Wawarsing',
    'U58': 'Woodstock'
  },
  'V': {  // Sullivan
    'V20': 'Bethel',
    'V22': 'Callicoon',
    'V24': 'Cochecton',
    'V26': 'Delaware',
    'V28': 'Fallsburg',
    'V30': 'Forestburgh',
    'V32': 'Fremont',
    'V34': 'Highland',
    'V36': 'Liberty',
    'V38': 'Lumberland',
    'V40': 'Mamakating',
    'V42': 'Neversink',
    'V44': 'Rockland',
    'V46': 'Thompson',
    'V48': 'Tusten'
  },
  'W': {  // Westchester
    'W08': 'Mt Vernon',
    'W10': 'New Rochelle',
    'W12': 'Peekskill',
    'W14': 'Rye City',
    'W17': 'White Plains',
    'W18': 'Yonkers',
    'W20': 'Bedford',
    'W22': 'Cortlandt',
    'W24': 'Eastchester',
    'W26': 'Greenburgh',
    'W28': 'Harrison',
    'W30': 'Lewisboro',
    'W32': 'Mamaroneck',
    'W34': 'Mount Pleasant',
    'W36': 'New Castle',
    'W38': 'North Castle',
    'W40': 'North Salem',
    'W42': 'Ossining',
    'W44': 'Pelham',
    'W46': 'Pound Ridge',
    'W48': 'Rye',
    'W50': 'Scarsdale',
    'W52': 'Somers',
    'W54': 'Yorktown',
    'W56': 'Mt Kisco'
  },
  'P': {  // Putnam
    'P20': 'Carmel',
    'P22': 'Kent',
    'P24': 'Patterson',
    'P26': 'Philipstown',
    'P28': 'Putnam Valley',
    'P30': 'Southeast'
  },
  'R': {  // Rockland
    'R20': 'Clarkstown',
    'R22': 'Haverstraw',
    'R24': 'Orangetown',
    'R26': 'Ramapo',
    'R28': 'Stony Point'
  }
};

function calculateRates(item, caseType) {
  const prefix = caseType.toLowerCase();
  
  // Calculate win rate based on the current case type
  const wonCases = (item[`${prefix}Won`] || 0) + 
                   (item[`${prefix}Settled`] || 0) + 
                   (prefix === 'scar' ? (item.scarNotInvoicable || 0) : 0);
  
  const totalResolvedCases = wonCases + 
                            (item[`${prefix}Denied`] || 0) + 
                            (item[`${prefix}Withdrawn`] || 0) + 
                            (prefix === 'scar' ? (item.scarStipNC || 0) : 0);

  const winRate = totalResolvedCases > 0 ? wonCases / totalResolvedCases : 0;

  // Calculate settlement rate based on current case type
  const totalSettlements = (item[`${prefix}Settled`] || 0) + 
                          (prefix === 'scar' ? (item.scarStipNC || 0) : 0) + 
                          (item[`${prefix}Withdrawn`] || 0);
  
  const totalCases = item[`${prefix}Total`] || 0;
  const settlementRate = totalCases > 0 ? totalSettlements / totalCases : 0;

  // Calculate decision win rate (SCAR only)
  const decisionWinRate = prefix === 'scar' && 
    (item.scarDecisions > 0 || item.scarNotInvoicable > 0) 
      ? (item.scarWon + (item.scarNotInvoicable || 0)) / 
        (item.scarDecisions + (item.scarNotInvoicable || 0))
      : 0;

  return {
    [`${prefix}WinRate`]: winRate,
    [`${prefix}SettlementRate`]: settlementRate,
    ...(prefix === 'scar' ? { scarDecisionWinRate: decisionWinRate } : {})
  };
}

// Move getFilterCondition outside both functions
function getFilterCondition(groupType, item, groupValue) {
  switch (groupType) {
    case 'county': return item.ParcelID?.charAt(0) === groupValue;
    case 'municipality': return item.ParcelID?.substring(0, 3) === groupValue;
    case 'hearingOfficer': return item.SCARHearingOfficer === groupValue;
    case 'negotiator': return item.Negotiator === groupValue;
    default: return false;
  }
}

function safeNumber(value) {
    const num = Number(value);
    return isNaN(num) ? 0 : num;
}

// {
//   "BARDeterminationAction": "BS",
//   "BARDeterminationValue": 61930,
//   "BARFiled": 1,
//   "BARRequestedValue": 18750,
//   "Negotiator": null,
//   "ParcelID": "W5601-069-082-00005-000-0001-0-1004",
//   "PercentReduction": null,
//   "PriorYearAssessment": 75000,
//   "SCARDeterminationAction": null,
//   "SCARDeterminationValue": null,
//   "SCARFiled": 0,
//   "SCARHearingOfficer": null,
//   "SCARRequestedValue": null,
//   "Taxes": 10772
// }

function processGroupData(data, groupType, existingResults, caseType) {
  if (!data || data.length === 0) return [];
  if (existingResults) return existingResults;

  const getGroupKey = (item) => {
    switch (groupType) {
      case 'county': return item.ParcelID?.charAt(0) || 'Unknown';
      case 'municipality': return item.ParcelID?.substring(0, 3) || 'Unknown';
      case 'hearingOfficer': return item.SCARHearingOfficer || null;  // Return null for filtering
      case 'negotiator': {
        // Map through userObject to find the matching user
        const negotiatorId = item.Negotiator;
        const matchingUser = Object.values(userObject).find(user => user.userId === Number(negotiatorId));
        return matchingUser ? matchingUser.userName : (negotiatorId || 'Unassigned');
      }
      default: return 'Unknown';
    }
  };

  const validData = data.filter(item => {
    const groupKey = getGroupKey(item);
    const shouldIncludeCase = 
      caseType === 'All' ||
      (caseType === 'SCAR' && item.SCARFiled === 1) ||
      (caseType === 'BAR' && item.BARFiled === 1);
    return groupKey !== null && shouldIncludeCase;
  });

  const groupedData = validData.reduce((acc, item) => {
    const groupKey = getGroupKey(item);
    
    if (!acc[groupKey]) {
      acc[groupKey] = {
        [groupType]: groupKey,
        totalCases: 0,
        scarTotal: 0,
        scarDecisions: 0,
        scarSettled: 0,
        scarDenied: 0,
        scarWithdrawn: 0,
        scarNotInvoicable: 0,
        scarAwaitingDecision: 0,
        scarAdminHold: 0,
        scarStipNC: 0,
        barTotal: 0,
        barWon: 0,
        barSettled: 0,
        barDenied: 0,
        barWithdrawn: 0,
        // Keep only the total assessment values
        totalPriorYearAssessment: 0,
        totalSCARDeterminationValue: 0,
        totalBARDeterminationValue: 0,
        totalTaxes: 0
      };
    }
    
    acc[groupKey].totalCases += 1;
    
    // Add to totals based on the selected case type
    const shouldIncludeCase = 
        caseType === 'All' ||
        (caseType === 'SCAR' && item.SCARFiled === 1) ||
        (caseType === 'BAR' && item.BARFiled === 1);

    if (shouldIncludeCase) {
        acc[groupKey].totalPriorYearAssessment += safeNumber(item.PriorYearAssessment);
        acc[groupKey].totalSCARDeterminationValue += safeNumber(item.SCARDeterminationValue);
        acc[groupKey].totalTaxes += safeNumber(item.Taxes);
    }

    // SCAR processing
    if (item.SCARFiled === 1) {
      acc[groupKey].scarTotal += 1;
      
      if (item.SCARDeterminationAction) {
        const isDecision = item.SCARHearingOfficer != null && item.SCARHearingOfficer !== 'Unassigned';
        
        switch (item.SCARDeterminationAction) {
          case 'S':
          case 'ST':
            if (isDecision) {
              if (!acc[groupKey].scarDecisions) acc[groupKey].scarDecisions = 0;
              if (!acc[groupKey].scarWon) acc[groupKey].scarWon = 0;
              acc[groupKey].scarDecisions += 1;
              acc[groupKey].scarWon += 1;
            } else {
              acc[groupKey].scarSettled += 1;
            }
            break;
          case 'SD': 
            if (isDecision) {
              if (!acc[groupKey].scarDecisions) acc[groupKey].scarDecisions = 0;
              if (!acc[groupKey].scarDenied) acc[groupKey].scarDenied = 0;
              acc[groupKey].scarDecisions += 1;
              acc[groupKey].scarDenied += 1;
            } else {
              if (!acc[groupKey].scarStipNC) acc[groupKey].scarStipNC += 1;
            }
            break;
          case 'NM':
            acc[groupKey].scarNotInvoicable += 1;
            break;
          case 'W': 
            acc[groupKey].scarWithdrawn += 1; 
            break;
          case 'D': 
            acc[groupKey].scarAwaitingDecision += 1; 
            break;
          case 'AH': 
            acc[groupKey].scarAdminHold += 1; 
            break;
          default: 
            break;
        }
        
        // Track decisions separately
        if (isDecision) {
          if (!acc[groupKey].scarDecisions) acc[groupKey].scarDecisions += 1;
        }
      } else {
        // If SCARFiled but no determination action and no hearing officer,
        // count as awaiting decision
        if (!item.SCARHearingOfficer || item.SCARHearingOfficer === 'Unassigned') {
          acc[groupKey].scarAwaitingDecision += 1;
        }
      }
    }

    // BAR processing
    if (item.BARFiled === 1) {
      acc[groupKey].barTotal += 1;
      
      // console.log('BAR Determination Action:', item.BARDeterminationAction, 'for ParcelID:', item.ParcelID);
      
      switch (item.BARDeterminationAction) {
        case 'BS':  // BAR Success - counts as a win
          acc[groupKey].barWon = (acc[groupKey].barWon || 0) + 1;
          break;
        case 'BD': // BAR Denied
          acc[groupKey].barDenied = (acc[groupKey].barDenied || 0) + 1;
          break;
        case 'BT': // BAR Settlement
          acc[groupKey].barSettled = (acc[groupKey].barSettled || 0) + 1;
          break;
        case 'W':  // Withdrawn
          acc[groupKey].barWithdrawn = (acc[groupKey].barWithdrawn || 0) + 1;
          break;
        default:
          console.log('Unknown BAR action:', item.BARDeterminationAction);
          break;
      }

      // Add BAR value calculations
      if (item.BARDeterminationValue) {
        acc[groupKey].totalBARDeterminationValue = (acc[groupKey].totalBARDeterminationValue || 0) + 
          safeNumber(item.BARDeterminationValue);
      }
    }
    
    return acc;
  }, {});

  const processedData = Object.values(groupedData).map(item => {
    const determinationValue = caseType === 'BAR' 
      ? item.totalBARDeterminationValue 
      : item.totalSCARDeterminationValue;

    const averageReduction = item.totalPriorYearAssessment > 0 
      ? (determinationValue - item.totalPriorYearAssessment) / item.totalPriorYearAssessment 
      : null;

    // Calculate total savings based on the correct determination value
    const totalSavings = item.totalPriorYearAssessment > 0 
      ? (item.totalPriorYearAssessment - determinationValue) * (item.totalTaxes / item.totalPriorYearAssessment)
      : 0;

    const scarFiledCount = data.filter(d => 
      getFilterCondition(groupType, d, item[groupType]) && d.SCARFiled
    ).length;
    
    const scarDeterminedCount = data.filter(d =>
      getFilterCondition(groupType, d, item[groupType]) 
    ).length;

    return {
      ...item,
      averagePercentReduction: averageReduction,
      totalSavings,
      ...calculateRates(item, caseType)
    };
  });

  return processedData;
}

function SettlementTable({ data, onBack, taxYear }) {
    const [groupBy, setGroupBy] = useState('county');
    const [groupedResults, setGroupedResults] = useState({});
    const [caseType, setCaseType] = useState('SCAR');
    const [selectedMuniCode, setSelectedMuniCode] = useState(null);
    const treeApiRef = useGridApiRef();
    
    const handleCellClick = useCallback((params) => {
        console.log('Cell click debug:', {
            field: params.field,
            rowPath: params.row.path,
            muniCode: params.row.municipality?.split(' - ')[0] || params.row.path[1]?.split(' - ')[0],
            fullRow: params.row
        });

        if (params.field === '__tree_data_group__' && params.row.path?.length === 2) {
            const muniCode = params.row.municipality?.split(' - ')[0] || 
                            params.row.path[1]?.split(' - ')[0];
            
            console.log('Setting municipal code:', muniCode);
            if (muniCode) {
                setSelectedMuniCode(muniCode);
                setGroupBy('municipality');
            }
        }
    }, []);

    useEffect(() => {
        if (groupBy === 'county' && treeApiRef.current) {
            const unsubscribe = treeApiRef.current.subscribeEvent('cellClick', handleCellClick);
            return () => {
                if (unsubscribe) {
                    unsubscribe();
                }
            };
        }
    }, [groupBy, handleCellClick]);

    // Filter data based on case type
    const filteredData = useMemo(() => {
        console.log('Filtering data:', {
            dataLength: data?.length,
            caseType,
            selectedMuniCode,
            groupBy
        });

        let filtered = data;
        
        // First apply case type filter
        if (caseType === 'SCAR') {
            filtered = data.filter(item => item.SCARFiled === 1);
        } else if (caseType === 'BAR') {
            filtered = data.filter(item => item.BARFiled === 1);
        }
        
        // Then apply municipality filter if needed
        if (selectedMuniCode && groupBy === 'municipality') {
            filtered = filtered.filter(item => {
                const itemMuniCode = item.ParcelID?.substring(0, 3);
                console.log('Checking item:', {
                    ParcelID: item.ParcelID,
                    itemMuniCode,
                    selectedMuniCode,
                    matches: itemMuniCode === selectedMuniCode
                });
                return itemMuniCode === selectedMuniCode;
            });
        }
        
        console.log('Filtered results:', {
            originalLength: data?.length,
            filteredLength: filtered?.length,
            sampleItems: filtered?.slice(0, 2)
        });
        
        return filtered;
    }, [data, caseType, selectedMuniCode, groupBy]);

    // Process data based on grouping type
    const processedData = useMemo(() => {
      console.log('Processing data:', {
        dataLength: filteredData.length,
        groupBy,
        hasExistingResults: !!groupedResults[groupBy]
      });
      const result = processGroupData(filteredData, groupBy, groupedResults[groupBy], caseType);
      setGroupedResults((prev) => ({
        ...prev,
        [groupBy]: result,
      }));
      return result;
    }, [filteredData, groupBy, groupedResults[groupBy], caseType]);
    console.log('processedData:', processedData)
  
    // Our stable reference to getTreeDataPath
    // Ensures it doesn't cause the grid to collapse on re-renders
    const getTreeDataPath = useCallback((row) => row?.path, []);
  
    // Transform processedData into tree data for county grouping
    const finalRows = useMemo(() => {
      if (groupBy !== 'county') {
        return processedData.map((item, index) => ({
          id: index,
          ...item,
        }));
      }

      // For county grouping, build parent + municipality children
      const treeRows = [];
      processedData.forEach((countyItem, index) => {
        const countyName = COUNTY_CODES[countyItem.county] || countyItem.county;
        const displayValue = countyName;
        
        // Process municipalities first
        const municipalityRows = [];
        const countyMunicipalities = data
          .filter(item => {
            // Filter based on county and case type
            const isCorrectCounty = item.ParcelID?.charAt(0) === countyItem.county;
            const isCorrectCaseType = caseType === 'All' || 
              (caseType === 'SCAR' && item.SCARFiled === 1) || 
              (caseType === 'BAR' && item.BARFiled === 1);
            return isCorrectCounty && isCorrectCaseType;
          })
          .reduce((acc, item) => {
            const municipalityCode = item.ParcelID?.substring(0, 3);
            if (!acc.has(municipalityCode)) {
              acc.set(municipalityCode, []);
            }
            acc.get(municipalityCode).push(item);
            return acc;
          }, new Map());

        // Process each municipality and collect their stats
        Array.from(countyMunicipalities.entries()).forEach(([municipalityCode, municipalityData], munIndex) => {
          const municipalityStats = processGroupData(municipalityData, 'municipality', null, caseType)[0];
          const countyCode = municipalityCode.charAt(0);
          const municipalityName = MUNICIPAL_CODES[countyCode]?.[municipalityCode] || municipalityCode;
          const municipalityDisplay = `${municipalityCode} - ${municipalityName}`;

          const muniRow = {
            id: `county-${index}-muni-${munIndex}`,
            path: [displayValue, municipalityDisplay],
            municipality: municipalityDisplay,
            ...municipalityStats,
            // Add these calculations explicitly
            revenue: municipalityStats.totalSavings * 0.5,
            dollarsPerFile: municipalityStats[`${caseType.toLowerCase()}Total`] > 0 
              ? (municipalityStats.totalSavings * 0.5) / municipalityStats[`${caseType.toLowerCase()}Total`]
              : 0
          };
          municipalityRows.push(muniRow);
        });

        // Calculate county totals using the correct case type
        const countyTotals = municipalityRows.reduce((acc, muni) => {
          const prefix = caseType.toLowerCase();
          const determinationValueField = `total${caseType}DeterminationValue`;
          
          // Only include values for the current case type
          const shouldInclude = caseType === 'All' || 
            (caseType === 'SCAR' && muni.scarTotal > 0) || 
            (caseType === 'BAR' && muni.barTotal > 0);

          if (shouldInclude) {
            return {
              ...acc,
              totalCases: (acc.totalCases || 0) + (muni.totalCases || 0),
              [`${prefix}Total`]: (acc[`${prefix}Total`] || 0) + (muni[`${prefix}Total`] || 0),
              [`${prefix}Won`]: (acc[`${prefix}Won`] || 0) + (muni[`${prefix}Won`] || 0),
              [`${prefix}Settled`]: (acc[`${prefix}Settled`] || 0) + (muni[`${prefix}Settled`] || 0),
              [`${prefix}Denied`]: (acc[`${prefix}Denied`] || 0) + (muni[`${prefix}Denied`] || 0),
              [`${prefix}Withdrawn`]: (acc[`${prefix}Withdrawn`] || 0) + (muni[`${prefix}Withdrawn`] || 0),
              totalPriorYearAssessment: (acc.totalPriorYearAssessment || 0) + (muni.totalPriorYearAssessment || 0),
              [determinationValueField]: (acc[determinationValueField] || 0) + (muni[determinationValueField] || 0),
              totalTaxes: (acc.totalTaxes || 0) + (muni.totalTaxes || 0),
              totalSavings: (acc.totalSavings || 0) + (muni.totalSavings || 0), // Sum municipality savings
              ...(caseType === 'SCAR' ? {
                scarStipNC: (acc.scarStipNC || 0) + (muni.scarStipNC || 0),
                scarDecisions: (acc.scarDecisions || 0) + (muni.scarDecisions || 0),
                scarAwaitingDecision: (acc.scarAwaitingDecision || 0) + (muni.scarAwaitingDecision || 0),
                scarAdminHold: (acc.scarAdminHold || 0) + (muni.scarAdminHold || 0),
                scarNotInvoicable: (acc.scarNotInvoicable || 0) + (muni.scarNotInvoicable || 0),
              } : {})
            };
          }
          return acc;
        }, {});

        // Calculate revenue based on summed savings
        countyTotals.revenue = countyTotals.totalSavings * 0.5; // Assuming 50% fee
        countyTotals.dollarsPerFile = countyTotals[`${caseType.toLowerCase()}Total`] > 0 
          ? countyTotals.revenue / countyTotals[`${caseType.toLowerCase()}Total`]
          : 0;

        // Calculate the reduction rate using the correct determination value
        const determinationValue = caseType === 'BAR' 
          ? countyTotals.totalBARDeterminationValue 
          : countyTotals.totalSCARDeterminationValue;

        const averageReduction = countyTotals.totalPriorYearAssessment > 0 
          ? (determinationValue - countyTotals.totalPriorYearAssessment) / countyTotals.totalPriorYearAssessment 
          : null;

        // Add county row with summed totals
        treeRows.push({
          id: `county-${index}`,
          path: [displayValue],
          county: displayValue,
          ...countyTotals,
          averagePercentReduction: averageReduction,
          ...calculateRates(countyTotals, caseType)
        });

        // Add all municipality rows
        treeRows.push(...municipalityRows);
      });

      return treeRows;
    }, [processedData, groupBy, data, caseType]);
  
    // Create a totals row to be pinned at the bottom
    const totalsRow = useMemo(() => {
      if (!finalRows.length) return null;

      // For county view, only sum the county-level rows (path.length === 1)
      // For other views, sum all rows
      const relevantRows = groupBy === 'county' 
        ? finalRows.filter(row => row.path?.length === 1)
        : finalRows;

      const totals = {
        id: 'totals',
        path: ['Total'],
        county: 'Total',
        municipality: 'Total',
        hearingOfficer: 'Total',
        negotiator: 'Total',
        totalCases: 0,
        scarTotal: 0,
        scarSettled: 0,
        scarStipNC: 0,
        scarWithdrawn: 0,
        scarDecisions: 0,
        scarAwaitingDecision: 0,
        scarAdminHold: 0,
        scarWon: 0,
        scarDenied: 0,
        scarNotInvoicable: 0,
        barTotal: 0,
        barSettled: 0,
        barWithdrawn: 0,
        barWon: 0,
        barDenied: 0,
        totalPriorYearAssessment: 0,
        totalSCARDeterminationValue: 0,
        totalBARDeterminationValue: 0,
        totalTaxes: 0,
        totalSavings: 0,
        revenue: 0,
      };

      // Sum numeric fields from county rows
      relevantRows.forEach(row => {
        Object.keys(totals).forEach(key => {
          if (typeof row[key] === 'number') {
            totals[key] += row[key] || 0;
          }
        });
      });

      // Calculate rates based on the summed values
      const totalCases = totals[`${caseType.toLowerCase()}Total`];
      if (totalCases > 0) {
        const prefix = caseType.toLowerCase();
        
        // Win rate
        const wonCases = (totals[`${prefix}Won`] || 0) + 
                        (totals[`${prefix}Settled`] || 0) + 
                        (prefix === 'scar' ? (totals.scarNotInvoicable || 0) : 0);
        
        const totalResolvedCases = wonCases + 
                                 (totals[`${prefix}Denied`] || 0) + 
                                 (totals[`${prefix}Withdrawn`] || 0) + 
                                 (prefix === 'scar' ? (totals.scarStipNC || 0) : 0);
        
        totals[`${prefix}WinRate`] = totalResolvedCases > 0 ? 
          wonCases / totalResolvedCases : 0;

        // Settlement rate
        const totalSettlements = (totals[`${prefix}Settled`] || 0) + 
                               (prefix === 'scar' ? (totals.scarStipNC || 0) : 0) + 
                               (totals[`${prefix}Withdrawn`] || 0);
        totals[`${prefix}SettlementRate`] = totalSettlements / totalCases;

        // Average reduction using the correct determination value
        const determinationValue = caseType === 'BAR' 
          ? totals.totalBARDeterminationValue 
          : totals.totalSCARDeterminationValue;

        totals.averagePercentReduction = totals.totalPriorYearAssessment > 0 ? 
          (determinationValue - totals.totalPriorYearAssessment) / 
          totals.totalPriorYearAssessment : 0;

        // Revenue and dollars per file based on summed savings
        totals.revenue = totals.totalSavings * 0.5; // Using the summed savings
        totals.dollarsPerFile = totals.revenue / totalCases;
      }

      return totals;
    }, [finalRows, groupBy, caseType]);

    // Define columns (unchanged except for referencing finalRows, if desired)
    const columns = useMemo(() => {
      if (processedData.length === 0) return [];
  
      // Special case for municipality view - show raw data
      if (groupBy === 'municipality') {
        const determinationField = caseType === 'BAR' ? 'BARDeterminationValue' : 'SCARDeterminationValue';
        const actionField = caseType === 'BAR' ? 'BARDeterminationAction' : 'SCARDeterminationAction';
        const hearingOfficerField = caseType === 'BAR' ? 'BARHearingOfficer' : 'SCARHearingOfficer';

        return [
          {
            field: 'ParcelID',
            headerName: 'Parcel ID',
            flex: 1,
            minWidth: 120,
          },
          {
            field: 'PriorYearAssessment',
            headerName: 'Prior Assessment',
            flex: 1,
            minWidth: 130,
            type: 'number',
            valueFormatter: (value) => {
              return value?.toLocaleString('en-US', { 
                style: 'currency', 
                currency: 'USD',
                maximumFractionDigits: 0 
              }) || '-';
            }
          },
          {
            field: determinationField,
            headerName: `${caseType} Value`,
            flex: 1,
            minWidth: 130,
            type: 'number',
            valueFormatter: (value) => {
              return value?.toLocaleString('en-US', { 
                style: 'currency', 
                currency: 'USD',
                maximumFractionDigits: 0 
              }) || '-';
            }
          },
          {
            field: actionField,
            headerName: `${caseType} Action`,
            flex: 1,
            minWidth: 110,
          },
          {
            field: hearingOfficerField,
            headerName: caseType === 'BAR' ? 'BAR Officer' : 'Hearing Officer',
            flex: 1,
            minWidth: 130,
          },
          {
            field: 'Negotiator',
            headerName: 'Negotiator',
            flex: 1,
            minWidth: 120,
            valueFormatter: (value) => {
              const negotiatorId = value;
              const matchingUser = Object.values(userObject).find(user => user.userId === Number(negotiatorId));
              return matchingUser ? matchingUser.userName : (negotiatorId || 'Unassigned');
            }
          },
          {
            field: 'Taxes',
            headerName: 'Taxes',
            flex: 1,
            minWidth: 120,
            type: 'number',
            valueFormatter: (value) => {
              return value?.toLocaleString('en-US', { 
                style: 'currency', 
                currency: 'USD',
                maximumFractionDigits: 0 
              }) || '-';
            }
          },
          {
            field: 'PercentReduction',
            headerName: 'Reduction %',
            flex: 1,
            minWidth: 120,
            type: 'number',
            valueFormatter: (value) => {
              return value ? `${(value * 100).toFixed(1)}%` : '-';
            }
          },
          {
            field: 'totalSavings',
            headerName: 'Total Savings',
            flex: 1,
            minWidth: 130,
            type: 'number',
            valueFormatter: (value) => {
              if (value === undefined) return '';
              return value.toLocaleString('en-US', { 
                style: 'currency', 
                currency: 'USD',
                maximumFractionDigits: 0 
              });
            },
          },
          {
            field: 'revenue',
            headerName: 'Revenue',
            flex: 1,
            minWidth: 130,
            type: 'number',
            valueFormatter: (value) => {
              if (value === undefined) return '';
              return value.toLocaleString('en-US', { 
                style: 'currency', 
                currency: 'USD',
                maximumFractionDigits: 0 
              });
            },
            hide: groupBy === 'municipality' || selectedMuniCode !== null // Hide in both municipality grouping and municipal view
          },
          ...(groupBy === 'county' ? [{
            field: 'dollarsPerFile',
            headerName: '$ / File',
            flex: 1,
            minWidth: 120,
            type: 'number',
            valueFormatter: (value) => {
              if (value === undefined) return '';
              return value.toLocaleString('en-US', { 
                style: 'currency', 
                currency: 'USD',
                maximumFractionDigits: 0 
              });
            }
          }] : []),
        ];
      }
  
      // Special columns for hearing officers
      if (groupBy === 'hearingOfficer') {
        return [
          {
            field: 'hearingOfficer',
            headerName: 'Hearing Officer',
            flex: 1,
            minWidth: 150,
          },
          {
            field: 'scarTotal',
            headerName: 'SCAR Cases',
            flex: 1,
            minWidth: 100,
            type: 'number',
          },
          {
            field: 'scarDecisions',
            headerName: 'Decisions',
            flex: 1,
            minWidth: 85,
            type: 'number',
          },
          {
            field: 'scarWon',
            headerName: 'Won',
            flex: 1,
            minWidth: 60,
            type: 'number',
            valueFormatter: (value) => value ?? '',
          },
          {
            field: 'scarDenied',
            headerName: 'Denied',
            flex: 1,
            minWidth: 70,
            type: 'number',
          },
          {
            field: 'scarNotInvoicable',
            headerName: 'Not Invoicable',
            flex: 1,
            minWidth: 110,
            type: 'number',
          },
          {
            field: 'scarDecisionWinRate',
            headerName: 'Decisions Won',
            flex: 1,
            minWidth: 120,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (value) => {
              const val = value;
              return val != null ? `${(val * 100).toFixed(1)}%` : '';
            },
          },
          {
            field: 'scarAwaitingDecision',
            headerName: 'Awaiting Decision', 
            flex: 1,
            minWidth: 130,
            type: 'number',
          },
          {
            field: 'scarAdminHold',
            headerName: 'Admin Hold',
            flex: 1,
            minWidth: 100,
            type: 'number',
          },
          {
            field: 'totalSavings',
            headerName: 'Total Savings',
            flex: 1,
            minWidth: 130,
            type: 'number',
            valueFormatter: (value) => {
              if (value === undefined) return '';
              return value.toLocaleString('en-US', { 
                style: 'currency', 
                currency: 'USD',
                maximumFractionDigits: 0 
              });
            }
          },
          {
            field: 'revenue',
            headerName: 'Revenue',
            flex: 1,
            minWidth: 130,
            type: 'number',
            valueFormatter: (value) => {
              if (value === undefined) return '';
              return value.toLocaleString('en-US', { 
                style: 'currency', 
                currency: 'USD',
                maximumFractionDigits: 0 
              });
            }
          }
        ];
      }
  
      const baseColumns = [
        // Only include the groupBy column if we're not in county view
        ...(groupBy !== 'county' ? [{
          field: groupBy,
          headerName:
            groupBy === 'hearingOfficer'
              ? 'Hearing Officer'
              : groupBy === 'negotiator'
              ? 'Negotiator'
              : 'Municipality',
          flex: 0,
          minWidth: 70,
        }] : []),
        {
          field: caseType === 'All' ? 'totalCases' : `${caseType.toLowerCase()}Total`,
          headerName: caseType === 'All' ? 'Total Cases' : `${caseType} Cases`,
          flex: 1,
          minWidth: 100,
          type: 'number',
        },
        {
          field: `${caseType.toLowerCase()}Settled`,
          headerName: 'Settled',
          description: `# of cases settled for reductions`,
          flex: 1,
          minWidth: 70,
          type: 'number',
        },
        // Only show these columns for SCAR
        ...(caseType === 'SCAR' ? [
          {
            field: 'scarStipNC',
            headerName: 'Stip NC',
            description: 'Stipulated No-change',
            flex: 1,
            minWidth: 75,
            type: 'number',
          },
          {
            field: 'scarDecisions',
            headerName: 'Decisions',
            description: 'Total SCAR cases that went to trial',
            flex: 1,
            minWidth: 85,
            type: 'number',
          },
          {
            field: 'scarWon',
            headerName: 'Won',
            description: 'SCAR cases won through decision',
            flex: 1,
            minWidth: 60,
            type: 'number',
            valueFormatter: (value) => value ?? '',
          },
          {
            field: 'scarDenied',
            headerName: 'Denied',
            description: 'SCAR cases denied',
            flex: 1,
            minWidth: 70,
            type: 'number',
          },
          {
            field: 'scarWithdrawn',
            headerName: 'Withdrawn',
            flex: 1,
            minWidth: 95,
            type: 'number',
          },
          {
            field: 'scarAwaitingDecision',
            headerName: 'Awaiting Decision',
            flex: 1,
            minWidth: 130,
            type: 'number',
          },
          {
            field: 'scarAdminHold',
            headerName: 'Admin Hold',
            flex: 1,
            minWidth: 100,
            type: 'number',
          },
          {
            field: 'scarNotInvoicable',
            headerName: 'Not Invoicable',
            flex: 1,
            minWidth: 110,
            type: 'number',
          },
          {
            field: 'scarDecisionWinRate',
            headerName: 'Decisions Won',
            flex: 1,
            minWidth: 120,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (value) => {
              const val = value;
              return val != null ? `${(val * 100).toFixed(1)}%` : '';
            },
          },
        ] : [
          // BAR-specific columns
          {
            field: 'barWon',
            headerName: 'Won',
            description: 'BAR cases with successful decisions',
            flex: 1,
            minWidth: 60,
            type: 'number',
            valueFormatter: (value) => value ?? '',
          },
          {
            field: 'barDenied',
            headerName: 'Denied',
            description: 'BAR cases denied',
            flex: 1,
            minWidth: 70,
            type: 'number',
          },
        ]),
        {
          field: `${caseType.toLowerCase()}WinRate`,
          headerName: 'Total Win %',
          description: 'Decisions + Settlements / Total Resolved Cases',
          flex: 1,
          minWidth: 100,
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: (value) => {
            const val = value;
            return val != null ? `${(val * 100).toFixed(1)}%` : '';
          },
        },
        {
          field: `${caseType.toLowerCase()}SettlementRate`,
          headerName: 'Settlement %',
          flex: 1,
          minWidth: 110,
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: (value) => {
            const val = value;
            return val != null ? `${(val * 100).toFixed(1)}%` : '';
          },
        },
        {
          field: 'totalTaxes',
          headerName: 'Total Tax',
          flex: 1,
          minWidth: 120,
          type: 'number',
          valueFormatter: (value) => {
            return value?.toLocaleString('en-US', { 
              style: 'currency', 
              currency: 'USD',
              maximumFractionDigits: 0 
            });
          }
        },
        {
          field: 'totalPriorYearAssessment',
          headerName: 'Prior Assessment',
          flex: 1,
          minWidth: 130,
          type: 'number',
          valueFormatter: (value) => {
            return value?.toLocaleString('en-US', { 
              style: 'currency', 
              currency: 'USD',
              maximumFractionDigits: 0 
            });
          }
        },
        {
          field: `total${caseType}DeterminationValue`,
          headerName: `${caseType} Value`,
          flex: 1,
          minWidth: 130,
          type: 'number',
          valueFormatter: (value) => {
            return value?.toLocaleString('en-US', { 
              style: 'currency', 
              currency: 'USD',
              maximumFractionDigits: 0 
            });
          }
        },
        {
          field: 'averagePercentReduction',
          headerName: 'Avg Reduction',
          flex: 1,
          minWidth: 120,
          type: 'number',
          valueFormatter: (value) => {
            if (value === undefined) return '';
            return `${(value * 100).toFixed(1)}%`;
          }
        },
        {
          field: 'totalSavings',
          headerName: 'Total Savings',
          flex: 1,
          minWidth: 130,
          type: 'number',
          valueFormatter: (value) => {
            if (value === undefined) return '';
            return value.toLocaleString('en-US', { 
              style: 'currency', 
              currency: 'USD',
              maximumFractionDigits: 0 
            });
          }
        },
        {
          field: 'revenue',
          headerName: 'Revenue',
          flex: 1,
          minWidth: 130,
          type: 'number',
          valueFormatter: (value) => {
            if (value === undefined) return '';
            return value.toLocaleString('en-US', { 
              style: 'currency', 
              currency: 'USD',
              maximumFractionDigits: 0 
            });
          }
        },
        // Only include dollarsPerFile in county tree view
        ...(groupBy === 'county' ? [{
          field: 'dollarsPerFile',
          headerName: '$ / File',
          flex: 1,
          minWidth: 120,
          type: 'number',
          valueFormatter: (value) => {
            if (value === undefined) return '';
            return value.toLocaleString('en-US', { 
              style: 'currency', 
              currency: 'USD',
              maximumFractionDigits: 0 
            });
          }
        }] : []),
      ];
  
      // If "municipality", you had some different logic in your code:
      if (groupBy === 'municipality') {
        return data && data.length > 0
          ? Object.keys(data[0])
              .filter(key => key !== 'dollarsPerFile') // Filter out dollarsPerFile
              .map((key) => ({
                field: key,
                headerName: key.charAt(0).toUpperCase() + key.slice(1),
                flex: 1,
                minWidth: 150,
              }))
          : [];
      }
  
      // Update the valueFormatter for number columns to handle zeros
      return baseColumns.map(col => {
        if (col.type === 'number') {
          return {
            ...col,
            valueFormatter: (value) => {
              // Special handling for percentage fields
              if (col.field.toLowerCase().includes('rate') || col.field === 'averagePercentReduction') {
                if (value === 0 || value == null) return '0%';
                return `${(value * 100).toFixed(1)}%`;
              }
              // Special handling for currency fields
              if (col.field.includes('revenue') || 
                  col.field.includes('Savings') || col.field.includes('File') ||
                  col.field.includes('Assessment') || col.field.includes('Value') ||
                  col.field.includes('Taxes')) {
                if (value === 0 || value == null) return '$0';
                return value.toLocaleString('en-US', { 
                  style: 'currency', 
                  currency: 'USD',
                  maximumFractionDigits: 0 
                });
              }
              // Default number handling
              if (value === 0 || value == null) return '0';
              return value.toLocaleString();
            },
            cellClassName: (params) => {
              const value = params.value;
              return (value === 0 || value == null) ? 'zero-value' : '';
            }
          };
        }
        return col;
      });
    }, [processedData, groupBy, caseType, data]);

    const groupingColDef = {
      maxWidth: 250,
      headerClassName: 'grouping-header',
      pinned: 'left',
      cellClassName: (params) => {
        // console.log('cellClassName params:', params);
        // Apply bold to county-level rows and clickable style to municipality rows
        if (params.row.path?.length === 1) return 'font-bold';
        if (params.row.path?.length === 2) return 'clickable-muni';
        return '';
      },
    };
  
    return (
      <Paper sx={{ width: '100%', mt: 2 }}>
        <Box
          sx={{
            p: 2,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Stack 
            direction="row" 
            alignItems="center" 
            spacing={2} 
            sx={{ flex: '1' }}
          >
            <IconButton onClick={onBack} size="small">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="h2">
              Aventine {taxYear} Results
            </Typography>
          </Stack>

          <Stack 
            direction="row" 
            alignItems="center" 
            spacing={2} 
            sx={{ flex: '2', justifyContent: 'center' }}
          >
            <ToggleButtonGroup
              value={groupBy}
              exclusive
              onChange={(event, newGrouping) => {
                if (newGrouping !== null) {
                  setGroupBy(newGrouping);
                  if (newGrouping !== 'municipality') {
                    setSelectedMuniCode(null);
                  }
                }
              }}
              aria-label="grouping options"
              size="small"
            >
              <ToggleButton value="county" aria-label="County">
                County
              </ToggleButton>
              <ToggleButton value="municipality" aria-label="Municipality">
                Municipality
              </ToggleButton>
              <ToggleButton value="hearingOfficer" aria-label="SCAR Hearing Officer">
                SCAR Hearing Officer
              </ToggleButton>
              <ToggleButton value="negotiator" aria-label="Negotiator">
                Negotiator
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Stack 
            direction="row" 
            alignItems="center" 
            spacing={2} 
            sx={{ flex: '1', justifyContent: 'flex-end' }}
          >
            <ToggleButtonGroup
              value={caseType}
              exclusive
              onChange={(event, newType) => {
                if (newType !== null) {
                  setCaseType(newType);
                }
              }}
              aria-label="case type"
              size="small"
            >
              <ToggleButton value="All" aria-label="All" disabled>
                All
              </ToggleButton>
              <ToggleButton value="SCAR" aria-label="SCAR">
                SCAR
              </ToggleButton>
              <ToggleButton value="BAR" aria-label="BAR">
                BAR
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>
  
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
          {groupBy === 'county' ? (
            <DataGridPro
              apiRef={treeApiRef}
              rows={finalRows}
              columns={columns}
              treeData
              getTreeDataPath={getTreeDataPath}
              groupingColDef={groupingColDef}
              treeDataGroupingProps={{
                groupingColDef: {
                  ...groupingColDef,
                }
              }}
              defaultGroupingExpansionDepth={0}
              pagination
              pageSize={15}
              rowsPerPageOptions={[15, 25, 50]}
              density="compact"
              slots={{ toolbar: GridToolbar }}
              disableDensitySelector
              disableColumnMenu
              initialState={{ 
                pinnedColumns: { left: ['__tree_data_group__'] },
              }}
              pinnedRows={{ bottom: [totalsRow] }}
              sx={{
                '& .clickable-muni': {
                  color: 'primary.main',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                    backgroundColor: 'action.hover',
                  }
                },
                '& .zero-value': {
                  color: 'text.disabled'
                },
                '& .MuiDataGrid-row--pinned': {
                  backgroundColor: 'grey.50',
                },
                '& .MuiDataGrid-row--pinned .MuiDataGrid-cell': {
                  fontWeight: 700,
                },
                flex: 1,
                height: 675,
              }}
            />
          ) : (
            <DataGridPro
              rows={finalRows}
              key='non-tree'
              columns={columns}
              disableDensitySelector
              pageSize={50}
              rowsPerPageOptions={[10, 25, 50]}
              disableRowSelectionOnClick
              density="compact"
              disableColumnMenu
              slots={{ toolbar: GridToolbar }}
              pinnedRows={groupBy === 'municipality' ? undefined : { bottom: [totalsRow] }}
              sx={{
                '& .zero-value': {
                  color: 'text.disabled'
                },
                '& .MuiDataGrid-row--pinned': {
                  backgroundColor: 'grey.50',
                },
                '& .MuiDataGrid-row--pinned .MuiDataGrid-cell': {
                  fontWeight: 700,
                },
                flex: 1,
                height: 675,
                minHeight: 675,
                maxHeight: 675,
              }}
            />
          )}
        </Box>
      </Paper>
    );
  }
  
  export default SettlementTable;
  