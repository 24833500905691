import React, { useState } from 'react';
import { useCompStore, useLocalDataStore, usePersistedDataStore } from '../store';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosConfig';

const AdjustmentsDialog = ({ open, onClose }) => {
  const getAdjustments = useCompStore((state) => state.adjustments);
  const setAdjustments = useCompStore((state) => state.setAdjustments);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  // const [adjustmentsUpdated, setAdjustmentsUpdated] = useState(false);
  const adjustmentsUpdated = useLocalDataStore((state) => state.settingsUpdated)
  const setAdjustmentsUpdated = useLocalDataStore((state) => state.setSettingsUpdated)
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);

  const displayLabels = {
    Acres: 'Land Area (Acres)',
    LotSqFt: 'Lot Area (SqFt)',
    Sqft: 'Living Area (SqFt)',
    Bath: 'Bathrooms (Full)',
    HalfBath: 'Bathrooms (Half)',
    FinishedBasement: 'Finished Basement SqFt',
    BasementSqFtUnfinished: 'Unfinished Basement SqFt',
    FullFinished: 'Full Finished Basement',
    FullPartialFinish: 'Partial Finished Basement',
    FullUnfinished: 'Full Unfinished Basement',
    Bulkhead: 'Bulkhead',
    CentralAir: 'Central Air Conditioning',
    Crawl: 'Crawl Space',
    Deck: 'Deck',
    Patio: 'Patio',
    Porch: 'Porch',
    InGroundPool: 'In-ground Pool',
    PoolAg: 'Above Ground Pool',
    PoolUnsure: 'Unspecified Pool',
    GarageDet: 'Detached Garage',
    TotalGarageCap: 'Garage Capacity',
    Fireplaces: 'Fireplaces',
    Kitchens: 'Kitchens',
    ConditionIn: 'Condition Adj (%)',
    Grade: 'Grade Adj (%)',
  };

  const adjustmentGroups = {
    GeneralFeatures: ['Acres', 'Sqft', 'LotSqFt', 'Bath', 'HalfBath', 'InGroundPool'],
    Basements: ['FinishedBasement', 'BasementSqFtUnfinished', 'FullFinished', 'FullPartialFinish', 'FullUnfinished'],
    Amenities: [
      'Bulkhead',
      'CentralAir',
      'Crawl',
      'Deck',
      'Patio',
      'Porch',
      'PoolAg',
      'PoolUnsure',
      'GarageDet',
      'TotalGarageCap',
      'Fireplaces',
      'Kitchens',
    ],
    Misc: ['ConditionIn', 'Grade'],
  };

  const percentageFields = ['ConditionIn', 'Grade'];
  const [localAdjustments, setLocalAdjustments] = useState(getAdjustments);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeKey, setActiveKey] = useState(null); // Tracks the active key for dropdown

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveKey(null);
  };

  const handleMenuSelect = (option) => {
    if (option === 'IFMV Linked') {
      setLocalAdjustments((prev) => ({
        ...prev,
        [activeKey]: 'IFMV Linked',
      }));
    } else {
      setLocalAdjustments((prev) => ({
        ...prev,
        [activeKey]: 0,
      }));
    }
    if(!adjustmentsUpdated){setAdjustmentsUpdated(true);}
    handleMenuClose();
  };

  const handleInputChange = (key, value) => {
    const numericValue = parseFloat(
      (typeof value === 'string' ? value.replace(/,/g, '') : value) || 0
    );
    setLocalAdjustments((prev) => {
      const updated = { ...prev, [key]: numericValue };

      // Automatically update LotSqFt if Acres changes
      if (key === 'Acres') {
        updated.LotSqFt = numericValue * 43560; // Convert Acres to SqFt
      }
      if(key === 'Bath'){
        updated.HalfBath = numericValue / 2;
      }

      return updated;
    });
    if (!adjustmentsUpdated) setAdjustmentsUpdated(true);
  };
  
  // saving function to update frontend adjustments and see how things change
  const handleSave = () => {
    const updatedAdjustments = {
      ...localAdjustments,
      userUpdated: true,
    };

    console.log('Updated Adjustments:', updatedAdjustments);
    toast.success('Adjustments updated successfully!');
    setAdjustments(updatedAdjustments);
    setAdjustmentsUpdated(false);
    onClose();
  };

  // open the db update confirmation, then kick off handleConfirmUpdate
  const handleDBSave = () => {
    setConfirmDialogOpen(true); // Open the confirmation dialog
  };

  const handleConfirmUpdate = async () => {
    const districtCode = getNegotiationObj.MuniCode.slice(1);
  
    // Key transformation mapping for the backend
    const keyMapping = {
      Bath: [['BathsFull', 'BathsHalf'], (value) => [value, value / 2]], // Derive both BathsFull and BathsHalf
      CentralAir: ['AC', (value) => value],
      FinishedBasement: ['BasementSqFtFinished', (value) => value],
      InGroundPool: ['PoolIg', (value) => value],
      Acres: ['LotSqFt', (value) => value / 43560], // Convert Acres to LotSqFt
      Sqft: ['GLA', (value) => value],
    };
  
    const handleSpecialCases = (key, value, backendAdjustments) => {
      if (key === 'TotalGarageCap') {
        const garageDet = value; // Example split logic
        const garageAtt = value;
        backendAdjustments['GarageDet'] = garageDet;
        backendAdjustments['GarageAtt'] = garageAtt;
      }
    };
  
    const prepareAdjustmentsForBackend = () => {
      const { countycode, ...remainingAdjustments } = localAdjustments;
  
      const backendAdjustments = {
        District: districtCode, // Add District
      };
  
      // Process adjustments with mappings and special cases
      Object.entries(remainingAdjustments).forEach(([key, value]) => {
        if (keyMapping[key]) {
          const [mappedKeys, transformFn] = keyMapping[key];
  
          // Check if mapping generates multiple backend keys
          if (Array.isArray(mappedKeys)) {
            const transformedValues = transformFn(value);
            mappedKeys.forEach((mappedKey, index) => {
              backendAdjustments[mappedKey] = transformedValues[index];
            });
          } else {
            backendAdjustments[mappedKeys] = transformFn(value);
          }
        } else if (key === 'TotalGarageCap') {
          handleSpecialCases(key, value, backendAdjustments);
        } else {
          backendAdjustments[key] = value; // Add keys without transformation
        }
      });
  
      // Explicitly set the countycode at the end to avoid overwrites
      backendAdjustments.countycode = getNegotiationObj.MuniCode
  
      return backendAdjustments;
    };
  
    const adjustmentsForBackend = prepareAdjustmentsForBackend();
    delete adjustmentsForBackend.HalfBath
  
    try {
      console.log('Adjustments for backend:', adjustmentsForBackend);
      const response = await axiosInstance.post('/update_adjustments', { adjustments: adjustmentsForBackend });
  
      if (response.status === 200) {
        const updatedLocalAdjustments = { ...localAdjustments, userUpdated: true };
        toast.success(`Adjustments updated, and saved in the database for ${getNegotiationObj.MuniCode} successfully!`);
        setAdjustments(updatedLocalAdjustments);
      } else {
        toast.error(`Failed to update adjustments: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error updating adjustments:', error);
      toast.error('An error occurred while updating adjustments. Please try again.');
    } finally {
      setConfirmDialogOpen(false);
      setAdjustmentsUpdated(false);
      onClose();
    }
  };
  
  
  

  
  const handleCancelUpdate = () => {
    setConfirmDialogOpen(false); // Close the confirmation dialog
  };

console.log('localAdjustments:', localAdjustments);
  return (
    // <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
    //   <DialogTitle>Edit Adjustments</DialogTitle>
    //   <DialogContent
    //     sx={{
    //       overflow: 'visible',
    //       display: 'flex',
    //       flexDirection: 'column',
    //       gap: 4,
    //     }}
    //   >
    <div className='justify-between h-full'>
      <>
        {/* Generic input, and custom handling for SQFT input */}
        <Box display="flex" gap={4}>
          {['GeneralFeatures', 'Basements'].map((group) => (
            <Box key={group} flex={1}>
              <Typography variant="h6" gutterBottom>
                {group === 'GeneralFeatures' ? 'General Features' : 'Basements'}
              </Typography>
              <Grid container spacing={2}>
                {adjustmentGroups[group]
                  .filter((key) => Object.keys(localAdjustments).includes(key))
                  .map((key) => (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      {key === 'Sqft' ? (
                        <>
                        {/* SQFT field */}
                          <TextField
                            fullWidth
                            label="Living Area (SqFt)"
                            value={
                              localAdjustments[key] === 'IFMV Linked'
                                ? 'IFMV Linked'
                                : localAdjustments[key].toLocaleString()
                            }
                            onChange={(e) => {
                              if (localAdjustments[key] !== 'IFMV Linked') {
                                handleInputChange(
                                  key,
                                  parseFloat(e.target.value.replace(/,/g, '')) || 0
                                );
                              }
                            }}
                            InputProps={{
                              startAdornment:
                                localAdjustments[key] !== 'IFMV Linked' ? (
                                  <InputAdornment position="start">$</InputAdornment>
                                ) : null,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={(e) => {
                                      // Anchor to the TextField itself
                                      setMenuAnchor(e.currentTarget.closest('.MuiFormControl-root'));
                                      setActiveKey(key);
                                    }}
                                    size="small"
                                    aria-label="open menu"
                                  >
                                    <ArrowDropDownIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            disabled={localAdjustments[key] === 'IFMV Linked'}
                            sx={
                              localAdjustments[key] === 'IFMV Linked'
                                ? { backgroundColor: '#f5f5f5' }
                                : {}
                            }
                          />
                          <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor) && activeKey === key}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left", // Align with the left edge of the TextField
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left", // Align with the left edge of the TextField
                            }}
                            PaperProps={{
                              style: {
                                width: menuAnchor?.offsetWidth || "auto", // Match TextField width
                              },
                            }}
                          >
                            <MenuItem onClick={() => handleMenuSelect('IFMV Linked')}>
                              IFMV Linked
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuSelect('Custom Value')}>
                              Custom Value
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          label={key}
                          disabled={key === 'LotSqFt' || key === 'HalfBath'}
                          value={localAdjustments[key].toLocaleString()}
                          onChange={(e) => handleInputChange(key, e.target.value)}
                          variant="outlined"
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ))}
        </Box>

        {/* Amenity section */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Amenities
          </Typography>
          <Grid container spacing={2}>
            {adjustmentGroups.Amenities.filter((key) =>
              Object.keys(localAdjustments).includes(key)
            ).map((key) => (
              <Grid item xs={12} sm={4} md={2} key={key}>
                <TextField
                  fullWidth
                  label={displayLabels[key] || key}
                  value={localAdjustments[key].toLocaleString()}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                  inputProps={{ maxLength: 10 }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: !percentageFields.includes(key) ? (
                      <InputAdornment position="start">$</InputAdornment>
                    ) : null,
                    endAdornment: percentageFields.includes(key) ? (
                      <InputAdornment position="end">%</InputAdornment>
                    ) : null,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Misc section */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Misc
          </Typography>
          <div className='flex'>
            {adjustmentGroups.Misc.filter((key) =>
              Object.keys(localAdjustments).includes(key)
            ).map((key) => (
              <Grid item xs={12} sm={4} md={3} key={key}>
                <TextField
                  fullWidth
                  label={displayLabels[key] || key}
                  value={localAdjustments[key].toLocaleString()}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                  inputProps={{ maxLength: 10 }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: !percentageFields.includes(key) ? (
                      <InputAdornment position="start">$</InputAdornment>
                    ) : null,
                    endAdornment: percentageFields.includes(key) ? (
                      <InputAdornment position="end">%</InputAdornment>
                    ) : null,
                  }}
                  sx={{
                    maxWidth: percentageFields.includes(key) ? '120px' : '100%',
                    width: percentageFields.includes(key) ? '100%' : 'auto',
                    marginRight: '8px',
                  }}
                />
              </Grid>
            ))}
            </div>
            
        </Box>
        </>

        {/* Only display these IF you are in the "in review" settings view */}
        <div className="absolute right-2 bottom-2 justify-end">
        <Button onClick={handleDBSave} disabled={!adjustmentsUpdated} color="error" variant="outlined" className='mr-2'
            sx={{
              '&.Mui-disabled': {
                color: 'gray !important',
                backgroundColor: 'transparent !important',
                borderColor: 'gray',
              },
            }}
            >
          Update DB
        </Button>

        <Button onClick={onClose} color="primary" className='mr-2'>
          Close
        </Button>
        <Button onClick={handleSave} disabled={!adjustmentsUpdated} color="primary" variant="contained">
          Save
        </Button>
        </div>

        {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelUpdate}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <Typography>
            This will overwrite the database's adjustments values <strong>PERMANENTLY</strong> for (municode). Confirm this is intentional.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpdate} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpdate} color="error" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>

        </div>
  );
};

export default AdjustmentsDialog;
