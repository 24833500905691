import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import MuniSelectionV2 from './MuniSelectionV2'; // Ensure correct import path
import Button from '@mui/material/Button';
import {useUser} from '../UserContext'
import Container from '@mui/material/Container';
import AnalysisAccordion from './AnalysisAccordion';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { getDefaultYear } from '../../common/helper_functions';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosConfig'
import { usePersistedDataStore, useCompStore, useLocalDataStore} from '../store';
import CircularProgress from '@mui/material/CircularProgress';
import {Navigate, NavLink} from 'react-router-dom'
import { isEqual } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useNYData } from '../../common/NyDataObject';
import AdvancedSettings from './AdvancedSettings';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SettlementTable from './SettlementTable';
import { useNavigate } from 'react-router-dom';

// if this doesn't update on comp location change you have succeeded.
// this is getting a bit bloated with the addition of additional settings etc.

// FOR THE OPTIMIZATIONS, follow these practices. https://medium.com/@sagarpatil23399/efficient-react-you-might-not-need-useeffect-95decbf2dbae
// Successsfuly made it so "comp" change doesn't rerender this.
function CaseAnalysisContainer({courtDates, globalCompRef}) {
  const [runType, setRunType] = useState('all_clients');
  const userObject = JSON.parse(localStorage.getItem('userInfo'));
  // const {processStream, loading, compRef} = useStreamProcessorNew();
  const [additionalInput, setAdditionalInput] = useState(''); // used for repid input or pid
  const getReportType = usePersistedDataStore((state) => state.reportType);
  const setReportType = usePersistedDataStore((state) => state.setReportType);
  const [propertyType, setPropertyType] = useState('residential');
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [courtDate, setCourtDate] = useState(null);
  const [courtDateOptions, setCourtDateOptions] = useState([]);
  const [selectedCourtDate, setSelectedCourtDate] = useState(null);
  const [repID, setRepID] = useState('');
  const [activeDialog, setActiveDialog] = useState(null);
  const [error, setError] = useState({ county: null, municipality: null, village: null });
  const { user } = useUser();
  const isRestricted = user?.userAccess?.level !== 'admin' && user?.userAccess?.level !== 'aventine';
  const setIsFetching = usePersistedDataStore((state) => state.setIsFetching);
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);
  const [reviewId, setReviewId] = useState(null);
  const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  const setAdjustments = useCompStore((state)=> state.setAdjustments)
  const [dataGridRows, setDataGridRows] = useState([]);
  const setMarketingResponse = usePersistedDataStore((state) => state.setMarketingResponse);
  const navigate = useNavigate();
  const [advancedSettings, setAdvancedSettings] = useState(() => {
    const storedSettings = localStorage.getItem('advancedSettings');
    return storedSettings
      ? JSON.parse(storedSettings)
      : {
          manualReview: true,
          defaultRunTypes: ['settled'],
          compFilters: [],
          dateRange: [null, null],
          squareFeetFilter: 40,
          salePriceFilter: 40,
          acreageFilter: 40,
        };
  });
  // should the user overwrite manually saved comps?
  const [overwriteComps, setOverwriteComps] = useState(false);
  const [settlementData, setSettlementData] = useState(null);

  console.log(advancedSettings)

  // Dynamically derive flags based on defaultRunTypes in settings
  const showSettledCases = advancedSettings?.defaultRunTypes?.includes('settled');
  const scarOnly = advancedSettings?.defaultRunTypes?.includes('scarFiled') || false;
  const manualReview = advancedSettings?.manualReview ?? true;
  const mlsComps = advancedSettings?.compFilters?.includes('mlsOnly') || false;
  const earliestDate = advancedSettings?.dateRange?.[0] || null;
  const latestDate = advancedSettings?.dateRange?.[1] || null;
  const manualReviewOverride = overwriteComps ?? false;
  const reviewedCasesFlag = advancedSettings?.defaultRunTypes?.includes('reviewed') || false;


  // Extracting location parameters without React Router to prevent their dumbass double render bug
  const [locationParams, setLocationParams] = useState(() => {
    const params = getLocationParams();
    // Initialize report type from URL param if it exists
    if (params.reportType) {
      setReportType(params.reportType);
    }
    return params;
  });
  function getLocationParams() {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      state: searchParams.get('state') || 'NY',
      county: searchParams.get('county') || '',
      municipality: searchParams.get('municipality') || '',
      village: searchParams.get('village') || '',
      TaxYear: parseInt(searchParams.get('TaxYear') || ''),
      MuniCode: searchParams.get('MuniCode') || '',
      municipalityString: searchParams.get('municipalityString'),
      reportType: searchParams.get('reportType') || 'standard' // Add default report type
    };
  }
  const { getCounties, getMunicipalities, getVillages } = useNYData();
  const { state, county, municipality, village, TaxYear } = locationParams;
  const counties = getCounties();
  const countyLookup = useMemo(
    () => Object.fromEntries(counties.map(({ key, selectVal }) => [selectVal, key])),
    [counties]
  );
  const municipalities = useMemo(() => {
    if (county) {
      // Use selectedCounty for dynamic lookup
      return getMunicipalities(countyLookup[county]) || [];
    }
    // Fallback to county if no selection
    return county ? getMunicipalities(countyLookup[county]) : [];
  }, [county, county, getMunicipalities, countyLookup, county]);
  
  const municipalityLookup = useMemo(
    () => Object.fromEntries(municipalities.map(({ key, selectVal }) => [selectVal, key])),
    [municipalities]
  );

  const updateLocationParams = useCallback((updates) => {

    setLocationParams((prev) => {
      // Merge the updates into the current state
      const nextParams = { ...prev, ...updates };
  
      // If the new state is deeply equal to the current state, return the current reference
      if (isEqual(prev, nextParams)) {
        return prev; // Same reference, no re-render
      }
  
      return nextParams; // New reference, triggers re-render
    });
  
    setError((prev) => {
      const updatedErrors = { ...prev };
  
      // Clear errors for any keys in updates
      Object.keys(updates).forEach((key) => {
        if (updatedErrors[key] && updatedErrors[key] !== null) {
          updatedErrors[key] = null;
        }
      });
  
      // If the errors are deeply equal, return the current reference
      if (isEqual(prev, updatedErrors)) {
        return prev; // Same reference, no re-render
      }
  
      return updatedErrors; // New reference, triggers re-render
    });
  }, []);

   // Handle updating settings from AdvancedSettings component
   const handleSettingsConfirm = useCallback((newSettings) => {
    // Update state
    console.log('udpating settings',newSettings)
    setAdvancedSettings(newSettings);
    // Save to localStorage
    localStorage.setItem('advancedSettings', JSON.stringify(newSettings));
    // display successfully updated message.
    toast.success('user default settings updated successfully.');

    console.log('Updated settings saved:', newSettings);
  }, []);
  // For the courtdate muni selection
    // Need to also return the munistring from this selectedcourtdate function.
  const handleSelectionChange = (event) => {
    const selectedObject = courtDateOptions.find(
      (option) => option.muni === event.target.value
    );
    setSelectedCourtDate(selectedObject.muni);
    // missing
    // , municipalityString:selectedObject.MuniName
    updateLocationParams({municipality:selectedObject.muni.slice(1,3),county:selectedObject.muni.slice(0,1),TaxYear:selectedObject.TaxYear, MuniCode:selectedObject.muni})
    console.log('Selected Option:', selectedObject); // Full object of the selected option
  };

  const handleRunTypeChange = (event) => {
    if (courtDate !== '') {
      setCourtDate('');
    }
    if((runType === 'single_pid' || runType === 'representative') && additionalInput !== '') {
      setAdditionalInput('');
    }

    if(runType === 'court_date' && error.courtDate) {
      setError((prev) => ({
        ...prev,
        courtDate: null,
      }));
    }

    if(runType === 'single_parcel' && error.additionalInput) {
      setError((prev) => ({
        ...prev,
        additionalInput: null,
      }));
    }

    // case to clear the rep input.
    setRunType(event.target.value);
    setAdditionalInput('');
  };

  const clearError = useCallback(
    (key) => {
      if(error[key] && error[key]!==null){
      setError((prev) => ({
        ...prev,
        [key]: null,
      }));
    }
    },
    [setError, error]
  );

  const handleActiveDialog = (value) => setActiveDialog(value);
  const handleCloseDialog = () => setActiveDialog(null);

  // UPDAT ETHIS CODE SO THAT IF THERE IS A SINGLE PID, don't require other params.
  // Some closure issue here
  const runAnalysis = useCallback(
    async ({ accordionId = null, force = false, overwriteComps: overwriteOverride = overwriteComps } = {}) => {
      console.log('Running analysis with overwriteComps:', overwriteOverride);
  
      setError({});

      // Validation rules based on report type and run type
      const validateLocation = () => {
        // For settlement runs, only validate county if doing specific area
        if (getReportType === 'settlement') {
          // For settlement runs with 'specific_area' type, require county selection
          if (runType === 'specific_area' && !locationParams.county) {
            setError(prev => ({ ...prev, county: 'Select a county.' }));
            return false;
          }
          // For 'all_clients' type, no location validation needed
          return true;
        }

        // For non-settlement runs, validate both county and municipality
        if (getReportType !== 'settlement') {
          if (!locationParams.county) {
            setError(prev => ({ ...prev, county: 'Select a county.' }));
            return false;
          }
          
          if (!locationParams.municipality && locationParams.county !== 'All') {
            setError(prev => ({ ...prev, municipality: 'Select a municipality.' }));
            return false;
          }
        }

        if (!locationParams.TaxYear) {
          setError(prev => ({ ...prev, TaxYear: 'Select a tax year.' }));
          return false;
        }

        return true;
      };

      // Run validations
      if (!validateLocation()) return;
  
      if (runType === 'all_clients' && getReportType !== 'settlement' && !force && (locationParams.county === 'All' || locationParams.municipality === 'All')) {
        setActiveDialog('largeQueryWarning');
        return;
      }
  
      if (runType === 'court_date' && !courtDate) {
        setError((prev) => ({
          ...prev,
          courtDate: 'Select a court date.',
        }));
        return;
      }
  
      if ((runType === 'single_parcel' || runType === 'representative') && !additionalInput) {
        const errorString = runType === 'single_parcel' ? 'Parcel ID' : 'Rep ID';
        setError((prev) => ({
          ...prev,
          additionalInput: `Enter a valid ${errorString}`,
        }));
        return;
      }
  
      console.log('force status', manualReview);
      if (!force && !manualReview) {
        setActiveDialog('manualReviewWarning');
        return;
      }
  
      setIsFetching(true);

      if(getReportType === 'settlement'){
        try {
          // Only include MuniCode if we have one
          const settlementParams = { 
            TaxYear: locationParams.TaxYear,
            CourtDate: courtDate
          };
          
          // Only add MuniCode for specific area runs
          if (runType === 'specific_area' && locationParams.MuniCode) {
            settlementParams.MuniCode = locationParams.MuniCode;
          }
          
          const settlementResponse = await axiosInstance.post(`/load_settlements`, settlementParams);
          
          setSettlementData(settlementResponse.data);
          setIsFetching(false);
          return;
        } catch (error) {
          console.error('Error fetching settlements:', error);
          toast.error('Failed to load settlement data');
          setIsFetching(false);
          return;
        }
      }
  
      // Safely handle cases where `accordionId` is not passed in
      if (accordionId) {
        setExpandedAccordion(accordionId);
      }
  
      const params = {
        runType, // all_clients, court_date, single_parcel, representative
        propertyType, // ex: residential, commercial, industrial
        getReportType,
        county: locationParams.county,
        municipality: locationParams.municipality,
        village: locationParams.village,
        TaxYear: parseInt(locationParams.TaxYear),
        MuniCode: locationParams.MuniCode,
        courtDate,
        overwriteComps: overwriteOverride,
        manualReview, // if false, save the comps automatically
        singlePid: additionalInput, // renamed for appropriate flag
      };
  
      // Example response simulation
      const firstResult = { id: 1, name: locationParams.municipalityString, value: 'Example Value' };
      setDataGridRows([firstResult]);
      setCompSheet([]);
      setOptimizedComps([]);
      setMarketingResponse([]);
  
      console.log('advanced settings');
      console.log(advancedSettings);
      console.log('is it mls only filter');
      console.log(advancedSettings.compFilters.includes('mlsOnly'));
  
      // Trigger initialization fetch
      const response = await handleInitializeFetch(params);
    },
    [
      runType,
      advancedSettings,
      locationParams,
      courtDate,
      additionalInput,
      propertyType,
      getReportType,
      overwriteComps,
      manualReview,
    ]
  );
  

  // Here begins the retarded old logic:
  // refactored the initialization, removed adjustment load.
  async function loadClientIds(muniObj) {
    console.log('Object going into load client IDs:', muniObj);
    // if the user is a training user, then set the TrainingUser to 1, otherwise set it to 0.
    // then the report type should be set to training
    
    try {
        const response = await axiosInstance.post(`/load_client_ids`, muniObj);
        const [clientList, negotiationCases] = response.data;
        // console.log('Client List:', clientList);
        // console.log('Negotiation Cases:', negotiationCases);
        return [clientList, negotiationCases]; // Return the parsed data
    } catch (err) {
        console.error('Error in loadClientIds:', err);
        // Pass a descriptive error message to be handled upstream
        throw new Error(err.response?.data?.message || 'Failed to load client IDs.');
    }
}


  // use this for the manual review false saving logic, otherwise rest should disappear.
  // the streaming update to this function removed the helper function entirely. TBD if that is desired behavior or not.
  const handleInitializeFetch = async (params, manualOverride = false) => {
    const {MuniCode, village, TaxYear, courtDate, runType, singlePid} = params;
    // here, set the sarchparams to have comp =1
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has('comp') || searchParams.get('comp') !== '1') {
      searchParams.set('comp', '1');
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }

    console.log('initialization params',params)
    let formattedCourtDate = null;
    // format the courtdate (confirm this is still necessary)
    if(courtDate){
      formattedCourtDate = courtDate
        ? new Date(new Date(courtDate).getTime() - new Date(courtDate).getTimezoneOffset() * 60000)
              .toISOString()
              .split('T')[0]
        : '';
        console.log('formatted court date',formattedCourtDate)
    }

    // reset your compref
    globalCompRef.current = {
        properties: [],
        cases: [],
        totalCases: 0,
    };

    // need to revisit this once settings are done - have it update these the user context level.
    // Need to load these in from the localstorage if they exist here.
    let updateNegotiationObj = {
        MuniCode,
        TaxYear,
        ...(formattedCourtDate ? { CourtDate: formattedCourtDate, CourtRun: 1 } : { CourtRun: 0 }),
        showSettledCases: showSettledCases, // abstract this to user level settings (stored local for now)
        manualReview: manualReview, // Same ^
        mlsCompsOnly: mlsComps, // ^
        earliestCutoffDate: earliestDate, // ^
        latestCutoffDate: latestDate, // ^
        reportType: getReportType, // ^
        villageRun: village !== 'All' ? 1 : 0,
        TrainingUser: userObject?.userAccess?.level === 'Training' ? 1 : 0,
        UserId: userObject?.userId || null, // this field is used for training users to select their comps
        manualReviewOverride: params.overwriteComps, // ^
        scarOnly: scarOnly, // ^
        reviewedCasesFlag: reviewedCasesFlag, // ^
        RepId: repID, // reimplement the rep run
    };

    // this is wholly rendundant fromt he above i think
    if (courtDate === '' || courtDate === null) {
        delete updateNegotiationObj.CourtDate;
    }

    try {
        // If you put in a single specific PID to run ()
        let updatedCases = []; // i think this is no longer relevant
        let negotiationCases = [];
        if (singlePid) {
            console.log('Running a single PID');
            console.log(singlePid)
            // updateNegotiationObj.cases = [singlePid];
            negotiationCases = [singlePid];
            updateNegotiationObj.MuniCode = singlePid.slice(0, 3); // Update MuniCode from PID
            updateNegotiationObj.overrideIds = [singlePid]; // Update overrideIds from PID
        } 
        // else {
            // Generic fetch for all cases
            console.log('pre load ids negoitiation obj',updateNegotiationObj)
            const clientIdResponse = await loadClientIds(updateNegotiationObj);
            console.log('client id response',clientIdResponse)
            // Temporary get adjustments
            const loadAdjustmentsResponse = await axiosInstance.post(`/load_adjustments`, updateNegotiationObj);
            let adjustments = null;
            const adjustmentResponse = loadAdjustmentsResponse.data;
            adjustments = adjustmentResponse.response;
            // console.log('client id response',clientIdResponse)
            updatedCases = clientIdResponse[0];
            negotiationCases = clientIdResponse[1];
            // console.log('updated cases',negotiationCases)
            
            // you could filter here for cases that are settled, unsettled or SCAR
            // if scarOnly === true, then filter for the updatedCases with SCARFiled === 1 only
            // filter so that you only include teh UIDS for cases which are scar filed if that setting is chosen.
            if(scarOnly && updateNegotiationObj.reportType !== 'marketing'){
              negotiationCases = negotiationCases.filter((caseItem) => caseItem.SCARFiled === 1);
              // Step 2: Filter updatedCases to only include cases where parcel_id matches a negotiationCase parcel_id
              const validParcelIds = new Set(negotiationCases.map((caseItem) => caseItem.parcel_id));

              // Step 3: Filter updatedCases to include only strings that exist in validParcelIds
              updatedCases = updatedCases.filter((caseId) => validParcelIds.has(caseId));
            }

            // Settled cases filter
            console.log(negotiationCases)
            console.log(updateNegotiationObj.reportType)
            if (!showSettledCases && updateNegotiationObj.reportType !== 'marketing') {
              console.log('performing filtering for showsettledcases')
              negotiationCases = negotiationCases.filter((caseItem) => {
                if (caseItem.SCARFiled === 1) {
                  // Include cases without SCARDeterminationValue
                  return !caseItem.SCARDeterminationValue || caseItem.SCARDeterminationValue === '';
                } else if (caseItem.BARFiled === 1) {
                  // Include cases without BARDeterminationValue
                  return !caseItem.BARDeterminationValue || caseItem.BARDeterminationValue === '';
                } else {
                  console.log('running third loop')
                  // If BARFiled is 0, blank string, or undefined, don't filter out
                  return caseItem.BARFiled === 0 || caseItem.BARFiled === '' || caseItem.BARFiled === undefined;
                }
              });
                console.log("After Settled Cases filtering:", negotiationCases);
            
                // Step 2: Update validParcelIds and updatedCases to match remaining negotiationCases
                console.log(updatedCases)
                const validParcelIds = new Set(negotiationCases.map((caseItem) => caseItem.parcel_id));
              //   updatedCases.forEach((caseId, index) => {
              //     // console.log(`Updated Case [${index}]:`, caseId, typeof caseId, validParcelIds.has(caseId));
              // });
              
              // Filter updatedCases based on validParcelIds
              updatedCases = updatedCases.filter((caseId) => {
                  // console.log('Checking caseId:', caseId, 'Exists in validParcelIds:', validParcelIds.has(caseId));
                  return validParcelIds.has(caseId);
              });
                // console.log("Updated Cases after Settled Cases filter:", updatedCases);
            }

            // perform if showSettledCases filtering:
            // Next, if the case is settled, remove any which have SCARDeterminationValue (or if SCARFiled === 0, if they have a BARDeterminationValue)

            if (updatedCases.length === 0 && updateNegotiationObj.reportType !== 'marketing') {
                console.log('No client IDs found, aborting fetch.');
                // reimplement this with your new settings
                toast.error(`No ${showSettledCases ? '' : 'unsettled'} clients found in this area for a ${updateNegotiationObj.reportType} run.`, {
                  autoClose: false,      // Prevents automatic dismissal
                  closeOnClick: true,    // Allows user to click to dismiss
                  draggable: true,       // Optional: allows dragging to close
                  // pauseOnHover: true,    // Optional: keeps toast open when hovering
              });
                setIsFetching(false);
                return;
            }

            const updatedNegotiationObj = { ...updateNegotiationObj, uids: updatedCases, cases: negotiationCases };
        console.log('Updated negotiation object:', updatedNegotiationObj);
        
        // here set your negotiation object -- no need to load settlements anymore
        // updatedNegotiationObj.cases = negotiationCases;
        setNegotiationObj(updatedNegotiationObj);
        setAdjustments(adjustments);
        setReviewPage(true);
        setReviewId(1)
        
        const searchParams = window.location.search; // Get current search params
        // Hardocded this for now to be equal to 1
        // return <Navigate to={`/test/1${searchParams}`} />;
        // Navigate to the desired route -- if it is a marketing run, you can navigate to a different component / table?
        // This navigate utilization causes double renders all down component tree (on param changes) - you can update this to be a local state, and conditionally render the Navgiate component instead.
        // console.log(updatedNegotiationObj)
        // if(updatedNegotiationObj.reportType==='marketing'){
        //   // here, navigate
        // }
        navigate(`/analysis/1${searchParams}`);
    } catch (error) {
      console.error('Error during fetch:', error);
      setIsFetching(false);
      toast.error(`Error: ${error.message}`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
      });
  } finally {
      console.log('KNN query is finished');
  }
  };

  const handleDialogClose = () => setActiveDialog(null);

  const handleDialogConfirm = (action) => {

    // when dxpancding to other run types, update the accordion ID type to be dyanmically set.
    if (activeDialog === 'manualReviewWarning') {
      const shouldOverwrite = action === 'overwrite';
      setOverwriteComps(shouldOverwrite); // Update the state asynchronously
      console.log('Overwrite Comps:', shouldOverwrite);
      runAnalysis({ accordionId: 'case', force: true, overwriteComps: shouldOverwrite });
    }
    if (activeDialog === 'largeQueryWarning') {
      runAnalysis({accordionId:'case', force:true}); // Force run analysis
    }
    handleDialogClose();
  };

  // const handleConfirmationClose = () => setConfirmationOpen(false);
  

  // Need to update this to return the muni string form this backend route to complete this flow fully.
  const handleSetCourtDateOptions = async (courtDate) => {
    setCourtDate(courtDate);
    // here, set the courtdate options state
    const response = await axiosInstance.post(`/load_court_munis`, { CourtDate: courtDate });
    // IF the length of the munis is more than one, then open the dialogue. otherwise, just set the value of the muni / county / year.
    setCourtDateOptions(response.data);

    // if multiple courtdates scheduled, have the user pick which to run.
    if(response.data.length > 1){
    setActiveDialog('courtDateSelection');
    }else{
      // this doesn't set the municipality string, how do we do that? ,municipalityString:response.data[0].MuniName
      const county = response.data[0].muni.slice(0,1);
      const municipality = response.data[0].muni.slice(1,3);
      // Update muni, county, taxyear.
      const countyString = countyLookup[county] || 'Unknown County';
      const municipalityLookup = Object.fromEntries(getMunicipalities(countyLookup[county]).map(({ key, selectVal }) => [selectVal, key]))
      const municipalityString = municipalityLookup[municipality] || 'Unknown Municipality';
      const muniCode = response.data[0].muni;
      const TaxYear = response.data[0].TaxYear;
      
      const searchParams = new URLSearchParams(window.location.search);

      // Update the search params with the new values
      const updates = { county, municipality, MuniCode: muniCode, countyString, municipalityString, TaxYear };
      Object.entries(updates).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    
      // Reflect the changes in the URL
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      updateLocationParams({municipality:response.data[0].muni.slice(1,3),county:response.data[0].muni.slice(0,1),TaxYear:response.data[0].TaxYear, MuniCode:response.data[0].muni})
    }
  }

  const handleAdditionalInputChange = useCallback((input) => {
    setAdditionalInput(input);
    // here, set the county and Muni
    if (input?.length > 5) {
      const county = input.slice(0, 1);
      const municipality = input.slice(1, 3);
      const muniCode = input.slice(0, 3);


      // Perform lookups, have to define dynamically so not stale refs.
      const countyString = countyLookup[county] || 'Unknown County';
      const municipalityLookup = Object.fromEntries(getMunicipalities(countyLookup[county]).map(({ key, selectVal }) => [selectVal, key]))
      const municipalityString = municipalityLookup[municipality] || 'Unknown Municipality';
      
      const searchParams = new URLSearchParams(window.location.search);

      // Update the search params with the new values
      const updates = { county, municipality, MuniCode: muniCode, countyString, municipalityString };
      Object.entries(updates).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    
      // Reflect the changes in the URL
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      
      updateLocationParams({
          municipality,
          county,
          MuniCode: muniCode,
          countyString: countyString,
          municipalityString: municipalityString,
      });
  } 

  // const { getCounties, getMunicipalities, getVillages } = useNYData();
    // updateLocationParams({municipality:response.data[0].muni.slice(1,3),county:response.data[0].muni.slice(0,1),TaxYear:response.data[0].TaxYear, MuniCode:response.data[0].muni})
  }, [updateLocationParams, countyLookup, municipalityLookup, municipalities]);

  // Modify the report type onChange handler
  const handleReportTypeChange = (event) => {
    const newReportType = event.target.value;
    setReportType(newReportType);
    
    // Update URL params
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('reportType', newReportType);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  // Define the width (max) of the thing you are passing in.
  // Need to make court date selection smaller.
  const fields = [
    {
      type: 'select',
      label: 'Property Type',
      value: propertyType,
      width: '140px',
      onChange: (event) => setPropertyType(event.target.value),
      options: [
        { label: 'Residential', value: 'residential' },
        { label: 'Commercial', value: 'commercial', disabled: true },
        { label: 'Industrial', value: 'industrial', disabled: true },
      ],
    },
    {
      type: 'select',
      label: 'Report',
      value: getReportType,
      width: '140px',
      onChange: handleReportTypeChange, // Use new handler
      options: [
        { label: 'Sales Analysis', value: 'standard' },
        { label: 'Assessment Analysis', value: 'assessment', },
        { label: 'Marketing Analysis', value: 'marketing', },
        ...(user.userAccess.level === 'admin' ? [
          { label: 'Settlement Analysis', value: 'settlement' }
        ] : [])
      ],
    },
    {
      type: 'select',
      label: 'Run Type',
      value: runType,
      onChange: handleRunTypeChange,
      width: '140px',
      options: getReportType === 'settlement' 
        ? [
            { label: 'All Clients', value: 'all_clients' },
            { label: 'Specific Area', value: 'specific_area' },
          ]
        : [
            { label: 'All Clients', value: 'all_clients' },
            { label: 'Court Date', value: 'court_date', disabled: isRestricted },
            { label: 'Single Parcel', value: 'single_parcel', disabled: isRestricted },
            { label: 'Representative', value: 'representative', disabled: true },
          ],
    },
    ...(runType === 'court_date'
      ? [
          {
            type: 'select',
            label: 'Court Date',
            value: courtDate,
            onChange: (event) => {
              if (error.courtDate) {
                setError((prev) => ({ ...prev, courtDate: null })); // Reset courtDate error
              }
              handleSetCourtDateOptions(event.target.value);
            },
            options: courtDates,
            error: error.courtDate,
            width: '140px',
          },
        ]
      : []),
    ...(runType === 'single_parcel' || runType === 'representative' || runType === 'all_clients'
      ? [
          {
            type: 'text',
            label: runType === 'single_parcel' ? 'Parcel ID' : 'Rep ID',
            value: additionalInput,
            width: runType === 'single_parcel' ? '220px' : '140px',
            visible: runType === 'all_clients' ? false : true,
            error: error.additionalInput,
            onChange: (event) => {
              if (error.additionalInput) {
                setError((prev) => ({ ...prev, additionalInput: null })); // Reset additionalInput error
              }
              // Need it here to set the county and Muni
              handleAdditionalInputChange(event.target.value);
              // setAdditionalInput(event.target.value);
            },
          },
        ]
      : []),
  ];

  const analysisButtons = [
    {
      type: 'icon',
      tooltip: 'Advanced Settings',
      onClick: () => handleActiveDialog('settings'),
    },
    {
      type: 'button',
      variant: 'contained',
      color: 'primary',
      label: 'Run',
      onClick: () => runAnalysis({ accordionId: 'case' }), // define which one to be opening
      disabled: getIsFetching, // Disable button when fetching
      endIcon: getIsFetching ? <CircularProgress size={16} sx={{
        color: 'white', // Force white color
      }}/> : null, // Show progress icon when fetching
    },
  ];

  // make sure you keep a stable ref for this & memoize the rows
  const dataGridProps = useMemo(
    () => ({
      columns: [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
          renderCell: (params) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center', // Vertically center content
                gap: 1,
                height: '100%', // Ensures proper alignment in the cell
                overflow: 'hidden', // Prevents overflow issues
              }}
            >
              <Typography
                sx={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // here, call the 
                  // setReviewId(params.id)
                  setReviewPage(true);
                  setReviewId(1)
                }
                }
              >
                {params.value}
              </Typography>
              {getIsFetching && <CircularProgress size={16} />}
            </Box>
          ),
        },
        { field: 'value', headerName: 'Value', width: 150 },
      ],
      rows: dataGridRows,
      localeText: { noRowsLabel: 'Run Analysis to see results' },
      pageSizeOptions: [5, 10, 20],
      disableColumnMenu: true,
      disableSelectionOnClick: true,
    }),
    [getIsFetching, dataGridRows]
  );

  // Programatic navigation to the next summary table screen.
  // if (reviewId !== null && getIsFetching) {
  //   // Render Navigate to redirect when reviewId is set
  //   const searchParams = window.location.search; // Get current search params
  //   return <Navigate to={`/analysis/${reviewId}${searchParams}`} />;
  // }

  return  (
    <div className='w-full h-full flex flex-col'>
    <Container
      maxWidth="xl" // Options: 'xs', 'sm', 'md', 'lg', 'xl' or false for no max width
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        py: 2,
      }}
    >

      {getReportType === 'settlement' && settlementData ? (
          <SettlementTable 
            data={settlementData} 
            onBack={() => {
              // setReportType(null);
              setSettlementData(null);
              setExpandedAccordion(null);
            }}
            taxYear={locationParams.TaxYear}
          />
        ) : (
          <>

      {/* The muni-multiselect (sets params) */}
      <MuniSelectionV2
        error={error}
        handleClearError={clearError}
        locationParams={locationParams}
        updateLocationParams={updateLocationParams}
        // defaultTaxYear={defaultTaxYear}
      />

      {/* move these cards to their own component */}
      {/* top display cards (most important stats for area): */}
    
      {/* Case Analysis container (finally, lol) */}
      <AnalysisAccordion
        title="Case Analysis"
        fields={fields}
        buttons={analysisButtons}
        dataGridProps={dataGridProps}
        expanded={expandedAccordion === 'case'}
        onExpandToggle={() =>
          setExpandedAccordion(expandedAccordion === 'case' ? null : 'case')
        }
      >
      </AnalysisAccordion>

      {/* Equity Analysis container - UNUSED */}
      <AnalysisAccordion
        title="Equity Analysis"
        fields={[]}
        buttons={analysisButtons}
        dataGridProps={dataGridProps}
        expanded={expandedAccordion === 'equity'}
        disabled={true}
        onExpandToggle={() =>
          // setExpandedAccordion(false)
          console.log('not ready yet')
        }
        tooltip='Coming Soon!'
      >
      </AnalysisAccordion>

      <Dialog open={!!activeDialog} onClose={handleDialogClose}>
          {activeDialog === 'manualReviewWarning' && (
            <>
              <DialogTitle>Manual Review Disabled
                {/* Close Icon */}
                <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  Manual review is disabled. Would you like to overwrite previously manually saved comps or keep them?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDialogConfirm('keep')}>Keep Comps</Button>
                <Button onClick={() => handleDialogConfirm('overwrite')} variant="contained" color="primary">
                  Overwrite Comps
                </Button>
              </DialogActions>
            </>
          )}

          {activeDialog === 'largeQueryWarning' && (
            <>
              <DialogTitle>Large Query Warning
                 {/* Close Icon */}
                 <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Typography>This query may take a long time to process. Are you sure you want to proceed?</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={() => handleDialogConfirm()} variant="contained" color="primary">
                  Run Anyway
                </Button>
              </DialogActions>
            </>
          )}

          {activeDialog === 'courtDateSelection' && (
            <>
              <DialogTitle>Municipality Selection
              <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Typography variant="body2" gutterBottom>
                  Please select which municipality you would like to run.
                </Typography>
                <Select
                  value={selectedCourtDate || ''}
                  onChange={(event) => setSelectedCourtDate(event.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select a Municipality
                  </MenuItem>
                  {courtDateOptions.map((option, index) => (
                    <MenuItem key={index} value={option.muni}>
                      {option.muni}
                    </MenuItem>
                  ))}
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Close</Button>
                <Button
                  onClick={() => {
                    console.log('Run with selected option:', selectedCourtDate);
                    handleDialogClose();
                    runAnalysis({accordionId:'case',  force: true });
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!selectedCourtDate}
                >
                  Run
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>

      {/* Settlements analysis placeholder */}
      {/* <AnalysisAccordion
        title="Settlement Analysis"
        fields={[]}
        buttons={buttons}
        dataGridProps={dataGridProps}
        expanded={expandedAccordion === 'settlement'}
        onExpandToggle={() =>
          setExpandedAccordion(expandedAccordion === 'settlement' ? null : 'settlement')
        }
      /> */}
      <AdvancedSettings
        open={activeDialog === 'settings'}
        onClose={handleCloseDialog}
        onConfirm={handleSettingsConfirm}
        initialSettings={advancedSettings}
        runAnalysis={runAnalysis}
      />

      {/* Court Municipality select Dialog */}
      {/* <Dialog open={openDialog === 'courtDateSelection'} onClose={handleCloseDialog}>
        <DialogTitle>Municipality Selection</DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            Please select which municipality you would like to run.
          </Typography>
          <Select
            value={selectedCourtDate || ''}
            onChange={handleSelectionChange}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              Select a Municipality
            </MenuItem>
            {courtDateOptions.map((option, index) => (
              <MenuItem key={index} value={option.muni}>
                {option.muni}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
          <Button
            onClick={() => {
              console.log('Run with selected option:', selectedCourtDate);
              handleCloseDialog();
              runAnalysis({accordionId:'case'})
            }}
            variant="contained"
            color="primary"
            disabled={!selectedCourtDate}
          >
            Run
          </Button>
        </DialogActions>
      </Dialog> */}

      </>
      )}
  </Container>
  </div>
  );
}

CaseAnalysisContainer.whyDidYouRender = true;
export default CaseAnalysisContainer;
